import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CredentialView from './sections/credentialView';
import DefaultView from './sections/defaultView';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/credentialserver/:diverid" element={<CredentialView />} />
        <Route exact path="/" element={<DefaultView />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
