import React, { useState, useEffect } from 'react';
import { List, Image, Row, Col } from 'antd';
import logo from '../gsa_logo.jpg';

import credentialsData from '../db/credentials.json';
import { useParams } from 'react-router-dom';

const CredentialView = () => {
	const [data, setData] = useState(null);
	const { diverid } = useParams();

	useEffect(() => {
		setData(credentialsData.credentials.find((x) => x.diver_id === diverid));
	}, [diverid]);

	return (
		<div>
			<Row justify={'center'}>
				<Col>
					<h1>Diver {diverid}</h1>
					{data ? (
						<List
							dataSource={data.certifications}
							renderItem={(item) => (
								<List.Item>
									<Image width={200} src={item.img_url} />
								</List.Item>
							)}
						/>
					) : (
						<p>Cargando datos...</p>
					)}
				</Col>
			</Row>
		</div>
	);
};

export default CredentialView;
