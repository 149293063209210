import React, { useState, useEffect } from 'react';
import logo from '../gsa_logo.jpg';

const defaultView = () => {

	return (
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>
					Edit <code>src/App.js</code> and save to reload.
				</p>
				<a
					className="App-link"
					href="https://divegsa.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					go to the website
				</a>
			</header>
		</div>
	);
}
export default defaultView